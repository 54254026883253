<template>
  <v-dialog v-model="dialog" persistent max-width="500">
    <template v-slot:activator="{ on, attrs }">
      <v-btn text block color="primary"
            v-bind="attrs"
            v-on="on">
        {{ label }}
      </v-btn>
    </template>
    <v-card v-if="isCompleted">
      <v-card-title class="headline">Password Reset Sent</v-card-title>
      <v-card-text>
        <p class="h4">Please check your inbox for instructions on how to reset your password</p>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" :loading="isLoading"
          @click.native="finish">Ok</v-btn>
      </v-card-actions>
    </v-card>
    <v-card v-else>
      <v-card-title class="headline">Password Reset</v-card-title>
      <v-card-text>
        <v-text-field
          label="E-mail"
          v-model="localEmail"
          :rules="[rules.required]"
        ></v-text-field>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="default" text @click.native="dialog = false">Cancel</v-btn>
        <v-btn color="primary" :loading="isLoading"
          @click.native="resetPassword">Reset Password</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import validation from './validation';

export default {
  mixins: [validation],
  props: {
    label: {
      type: String,
      default: 'Forgot your password?',
    },
    email: {
      type: String,
      default: '',
    },
  },
  watch: {
    email() {
      this.localEmail = this.email;
    },
  },
  data() {
    return {
      dialog: false,
      localEmail: this.email,
      isLoading: false,
      isCompleted: false,
    };
  },
  methods: {
    async resetPassword() {
      this.isLoading = true;
      await this.$auth.forgot(this.localEmail);
      this.isLoading = false;
      this.isCompleted = true;
    },
    finish() {
      this.dialog = false;
      this.isCompleted = false;
    },
  },
};
</script>
