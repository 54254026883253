<template>
  <v-data-table
    :headers="headers"
    :items="allProducts"
    class="elevation-1"
    :loading="isLoading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar
        flat
        height="80"
      >
        <v-toolbar-title>Products</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
        />
        <v-spacer />
        <v-text-field
          v-model="search"
          label="Search"
        ></v-text-field>
      </v-toolbar>
    </template>
    <template v-slot:item.merchandiseImgUrl="{ item }">
      <v-img :src="item.merchandiseImgUrl" width="128" contain></v-img>
    </template>
    <template v-slot:item.merchandiseDescription="{ item }">
      <div v-html="item.merchandiseDescription"></div>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-btn small :to="{
          name: 'products-EditProduct',
          query: {id: item.stockCode}
        }"
      >
        Edit
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import loading from '@codehq/aurora-app-core/src/mixins/loading';

export default {
  name: 'AdminProductList',
  module: 'Product',
  mixins: [loading],
  data() {
    return {
      allProducts: [],
      search: '',
    };
  },
  computed: {
    /** Vuex states
     */
    ...mapState('products', ['products']),
    /**
    * Get table column headers
    */
    headers() {
      return [
        {
          text: 'Photo',
          value: 'merchandiseImgUrl',
        },
        {
          text: 'Stock Code',
          value: 'stockCode',
        },
        {
          text: 'Name',
          value: 'stockDescription',
        },
        {
          text: 'Product Group',
          value: 'productGroup',
        },
        {
          text: 'Description',
          value: 'merchandiseDescription',
        },
        {
          text: '',
          value: 'actions',
        },
      ];
    },
  },
  async mounted() {
    this.isLoading = true;
    const { data } = await this.$http.get('odata/products?$filter=IsOmni eq true');
    this.allProducts = data.value;
    this.isLoading = false;
  },
  methods: {
    /** Vuex methods
     */
    ...mapActions('products', ['LOAD_products', 'DELETE_product', 'UPLOAD_product']),
  },
};
</script>

<docs>
# Product example

'''js
<Product />
'''
</docs>
