/* eslint-disable no-param-reassign */
/* eslint-disable no-unused-expressions */
import 'babel-polyfill';
import 'whatwg-fetch';
import 'unorm';
import Vue from 'vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { o } from 'odata';
import { mapState } from 'vuex';
import VueCarousel from 'vue-carousel';
import VuetifyConfirm from 'vuetify-confirm';
import VCalendar from 'v-calendar';
import VueOwinAuth from '@codehq/vue-owin-auth/src/vueOwinAuth';
import vueDebounce from 'vue-debounce';
import dayjs from 'dayjs';

// Transpiler for older browsers
import 'core-js/stable';
// optional but required for transforming generator fns.
import 'regenerator-runtime/runtime';
import VueSignaturePad from 'vue-signature-pad';
import App from './App.vue';
// import authHub from './authHub';
import i18n from './i18n';
import vuetify from './plugins/vuetify';
import store from './store';
import router from './router';

// PWA Service worker
// if (process.env.NODE_ENV === 'production') {
// // eslint-disable-next-line global-require
//   const wb = require('./registerServiceWorker');

//   Vue.prototype.$workbox = wb;
//   window.$workbox = wb;

//   console.debug('workbox', wb);
// }
const wb = require('./registerServiceWorker');

Vue.prototype.$workbox = wb;
window.$workbox = wb;
Vue.config.productionTip = false;

// setup auth
Vue.use(VueOwinAuth, {
  url: process.env.VUE_APP_APIURL,
  client_id: process.env.VUE_APP_CLIENT_ID,
  client_secret: process.env.VUE_APP_CLIENT_SECRET,
});

Vue.use(VueCarousel);

// Setup API
Vue.use(VueAxios, axios);
axios.defaults.baseURL = process.env.VUE_APP_APIURL;
o(process.env.VUE_APP_APIURL);

store.$http = axios;

// Setup shared custom components
Vue.use(VCalendar);
// Vue.component('v-date-picker', DatePicker);
Vue.use(VueSignaturePad);

Vue.use(VuetifyConfirm, { vuetify });
Vue.prototype.$log = {
  info(msg) {
    console.log(msg);
  },
};

Vue.use(vueDebounce);

// format number
Vue.mixin({
  computed: {
    /**
    * Get the i18n local prefix based on the module and name of the component
    */
    localePrefix() {
      return `${this.$options.module}.${this.$options.name}`;
    },
    elev() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        return 3;
      }
      return 0;
    },
  },
  methods: {
    /**
    * Translate for module
    */
    $$t(name, obj) {
      return this.$t(`${this.$options.module}.${this.$options.name}.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$tc(name, obj) {
      return this.$tc(`${this.$options.module}.${this.$options.name}.${name}`, obj);
    },
    /**
    * Translate with pluralization for module
    */
    $$d(name, obj) {
      return this.$d(`${this.$options.module}.${this.$options.name}.${name}`, obj);
    },
    formatCurrency(n) {
      const localeString = (x, sep, grp) => {
        const sx = (`${x}`).split('.'); let s = ''; let i; let
          j;
        sep || (sep = ' '); // default seperator
        grp || grp === 0 || (grp = 3); // default grouping
        i = sx[0].length;
        while (i > grp) {
          j = i - grp;
          s = sep + sx[0].slice(j, i) + s;
          i = j;
        }
        s = sx[0].slice(0, i) + s;
        sx[0] = s;
        return sx.join('.');
      };
      return `${localeString(parseFloat(n)?.toFixed(2))}`;
    },
    formatDateTime(dt) {
      if ((dt?.length ?? 0) > 0) {
        return dayjs(dt).format('YYYY-MM-DD HH:mm');
      }
      return '';
    },
    formatTime(dt) {
      if ((dt?.length ?? 0) > 0) {
        return dayjs(dt).format('HH:mm');
      }
      return '';
    },
    formatDate(dt) {
      if ((dt?.length ?? 0) > 0) {
        return dayjs(dt).format('YYYY-MM-DD ');
      }
      return '';
    },
    formatSentenceCase(str) {
      return str.replace(/\w\S*/g, (txt) => txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase());
    },
  },
});

new Vue({
  vuetify,
  store,
  router,
  i18n,
  computed: {
    ...mapState('app', ['token']),
  },
  created() {
    axios.interceptors.request.use((config) => {
      // eslint-disable-next-line no-param-reassign
      config.headers.common.Authorization = `Bearer ${this.token}`;
      return config;
    }, (error) => Promise.reject(error));
    // axios.interceptors.response.use((response) => response, (error) => {
    //   if (error.response.status === 401) {
    //     authHub.$emit('user.unauthorized');
    //   }
    //   return Promise.reject(error);
    // });
    // this.$telemetry(navigator.userAgent);
  },
  render: (h) => h(App),
}).$mount('#app');
